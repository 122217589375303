export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K8s cluster status"])},
        "nodeCount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " nodes, ", _interpolate(_named("etcdCount")), " etcd members in k8s cluster"])},
        "unreachable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannot reach cluster"])},
        "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading"])}
      },
      "zh": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K8S 集群状态"])},
        "nodeCount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["K8S 集群中已有 ", _interpolate(_named("count")), " 个节点，ETCD 集群中已有 ", _interpolate(_named("etcdCount")), " 个成员"])},
        "unreachable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不能访问集群"])},
        "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加载中"])}
      }
    }
  })
}
