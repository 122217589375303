export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "getKubeconfig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fetch kubeconfig"])},
        "accessFromControlPlane": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use kubectl on control_plane"])},
        "accessMethods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can use differenct ways to access the cluster"])},
        "controlPlanes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSH connect to any of the following nodes, and use kubectl command to administrate the cluster."])},
        "proposeKuboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuboard is a popular K8S cluster management UI, you can refer to its website to learn how to install and use it."])},
        "switchToPlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swith to cluster plan view."])},
        "etcdAccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSH connect to any of the following nodes, and use etcdctl command to administrate the etcd cluster."])},
        "requiredToSyncEtcd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You removed a etcd node, and it's a must to sync etcd config to all kube_control_plane and etcd nodes."])},
        "yourcommand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Execute your command starting from here."])}
      },
      "zh": {
        "getKubeconfig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["获取 kubeconfig 文件"])},
        "accessFromControlPlane": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在主节点上使用 kubectl"])},
        "accessMethods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您可以使用多种方式对集群进行管理控制"])},
        "controlPlanes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您可以 ssh 到如下节点中的任意一个，直接执行 kubectl 命令可以管理集群。"])},
        "proposeKuboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kuboard 是一款非常值得推荐的 K8S 集群管理界面，请参考 Kuboard 网站，安装该管理界面。"])},
        "switchToPlan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["切换到集群规划页"])},
        "etcdAccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您可以 ssh 到如下节点中的任意一个，执行以下指令后，可以通过 etcdctl 操作 etcd 集群。通常您并不需要直接操作 etcd。"])},
        "requiredToSyncEtcd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您删除了 ETCD 节点，必须将 ETCD 配置同步到所有控制节点和 ETCD 节点"])},
        "yourcommand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此处开始，执行您想要执行的 etcdctl 命令"])}
      }
    }
  })
}
