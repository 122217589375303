export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "bastionUsage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KuboardSpray can access Kubernetes Cluster Nodes through bastion."])},
        "addSshKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Private Key"])},
        "ansible_host_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KuboardSpray use this ip or hostname to connect to the node."])},
        "default_value": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Default: ", _interpolate(_named("default_value")), " (inhirit from value configured in Global Config tab)"])},
        "duplicateIP": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["IP address conflict with ", _interpolate(_named("node"))])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access target nodes throguh bastion/jumpserver【Cannot work now.】"])}
      },
      "zh": {
        "bastionUsage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KuboardSpray 可以通过跳板机或堡垒机访问将要安装 K8S 集群的目标节点。"])},
        "addSshKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加私钥"])},
        "ansible_host_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KuboardSpray 连接该主机时所使用的主机名或 IP 地址"])},
        "default_value": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["默认值：", _interpolate(_named("default_value")), " （继承自全局设置标签页中的配置）"])},
        "duplicateIP": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["IP 地址不能与其他节点相同：", _interpolate(_named("node"))])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过跳板机或者堡垒机访问目标节点【待完善】"])}
      }
    }
  })
}
