export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "cluster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cluster"])},
        "clusterList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clusters List"])},
        "plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cluster Plan"])},
        "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access Cluster"])},
        "node": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nodes Maintainance"])},
        "health_check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Health Check"])},
        "backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backup/Restore"])},
        "csi_scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSI Scan"])},
        "upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade Check"])}
      },
      "zh": {
        "clusterList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["集群列表"])},
        "cluster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["集群"])},
        "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["访问集群"])},
        "plan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["集群规划"])},
        "nodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["节点维护"])},
        "health_check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状态检查"])},
        "backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["备份/恢复"])},
        "csi_scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CIS扫描"])},
        "upgrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["升级包检测"])}
      }
    }
  })
}
