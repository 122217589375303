export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ContainerManager"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Container Manager"])},
        "insecure_registries_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e.g. 172.19.16.11:5000, use http protocol to pull from the registry"])},
        "registry_mirrors_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registry Mirrors"])}
      },
      "zh": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["容器引擎"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["设置集群范围内所有节点使用的容器引擎相关参数"])},
        "insecure_registries_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["例如：172.19.16.11:5000，使用 http 协议从该仓库拉取镜像"])},
        "registry_mirrors_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["镜像仓库 mirror，例如：https://registry.docker-cn.com"])}
      }
    }
  })
}
