export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Node Info"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Node info retrived from kubernetes cluster."])}
      },
      "zh": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["节点信息"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从 K8S 获取到的节点信息"])}
      }
    }
  })
}
